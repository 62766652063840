import React from "react"
import "_data/normalize.css"
import "@fontsource/montserrat/800.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/500.css"
import { motion } from "framer-motion"
import PageHeadline from "_components/misc/pageHeadline"
import Header from "_components/nav/header/_header"
import Seo from "_components/Seo/changelog"
import Footer from "_components//nav/footer/_footer"
import { Link } from "gatsby"
import { color_textLink } from "_src/assets/data/global_colors"
import {
  Paragraph,
  Screen,
  Content,
  HeaderContainer,
} from "_data/global_styles"

const ChangeLog = (props) => {
  return (
    <>
      {!props.skipHeader && (
        <>
          <Seo />
          <HeaderContainer>
            <Header />
          </HeaderContainer>
        </>
      )}
      <Screen>
        <Content>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            <PageHeadline copy="Application Roadmap" />
            <div style={{ marginLeft: "1vw", color: "#303c6c" }}>
              <Paragraph>
                Version 1.0 was released on March 5th, 2021. Along with this
                release came many new features and a brand new look. We have a
                list of improvements we'd like to make and currently sorting out
                the order that it should happen in.
              </Paragraph>
              <Paragraph>
                {!props.skipHeader && (
                  <>
                    Please join the{" "}
                    <Link
                      style={{
                        color: color_textLink,
                        fontWeight: "800",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                      to="/signup"
                    >
                      mailing list
                    </Link>{" "}
                    to be updated about new features, changes, issues,
                    workarounds, and tips.{" "}
                  </>
                )}
              </Paragraph>

              <Paragraph>
                We are excited about the future of our tools and currently
                working through quite a number of unique ideas that will make
                ActiveEMDR more valuable than ever.
              </Paragraph>
            </div>
          </motion.div>
        </Content>
        {!props.skipHeader && <Footer />}
      </Screen>
    </>
  )
}

export default ChangeLog
